// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-video-js": () => import("/opt/build/repo/src/templates/post-video.js" /* webpackChunkName: "component---src-templates-post-video-js" */),
  "component---src-templates-post-album-js": () => import("/opt/build/repo/src/templates/post-album.js" /* webpackChunkName: "component---src-templates-post-album-js" */),
  "component---src-templates-post-comic-js": () => import("/opt/build/repo/src/templates/post-comic.js" /* webpackChunkName: "component---src-templates-post-comic-js" */),
  "component---src-templates-post-love-js": () => import("/opt/build/repo/src/templates/post-love.js" /* webpackChunkName: "component---src-templates-post-love-js" */),
  "component---src-templates-post-article-js": () => import("/opt/build/repo/src/templates/post-article.js" /* webpackChunkName: "component---src-templates-post-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

